import React, { FC } from 'react';
import { GraphCardHeader } from 'components/GraphCardHeader';
import { Doughnut } from 'react-chartjs-2';
import { formatNumber } from '../../helpers';

import styles from './styles.module.scss';

interface Props {
    title: string;
    icon: string;
    organizationName: string;
    circularPercentage: number;
    progressBarTitle: string;
    progressBarDescription: string;
    progressBarPercentage: number;
    description: string;
    progressBarBottomTitle: string;
    hasProgressBar?: boolean;
    hasProgressBarPercentage?: boolean;
}

const OrganizationExecutiveStatsItem: FC<Props> = ({
    title,
    icon,
    circularPercentage,
    progressBarTitle,
    progressBarDescription,
    progressBarPercentage,
    description,
    progressBarBottomTitle,
    hasProgressBar = true,
    hasProgressBarPercentage = true,
}: Props) => {
    const avgPercentage = formatNumber(circularPercentage);
    const diversificationPercentage = formatNumber(progressBarPercentage);

    const circularData = {
        datasets: [
            {
                data: [avgPercentage, 100 - Number(avgPercentage)],
                backgroundColor: ['#4effdf', '#dbeafe'],
                borderWidth: 0,
            },
        ],
    };

    const circularOptions = {
        cutout: '70%',
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
        maintainAspectRatio: false,
    };

    const barPercentage = diversificationPercentage;

    return (
        <div className={styles.treasuryStats}>
            <div className={styles.treasuryStats__container}>
                <div className={styles.treasuryStats__container__header}>
                    <GraphCardHeader className={styles.treasuryStats__container__title} title={title} />
                    <img src={icon} width={30} height={30} alt={`${title} icon`} />
                </div>
                <div className={styles.treasuryStats__container__content}>
                    <div className={styles.treasuryStats__container__content__box}>
                        <div className={styles.treasuryStats__container__content__box__title}>{progressBarTitle}</div>
                        <div className={styles.treasuryStats__container__content__box__progressBar}>
                            {hasProgressBar && (
                                <div className={styles.treasuryStats__container__content__box__progressBar_wrapper}>
                                    <div
                                        className={styles.treasuryStats__container__content__box__progressBar_fill}
                                        style={{ height: `${barPercentage}%` }}
                                    ></div>
                                </div>
                            )}
                            <div className={styles.treasuryStats__container__content__box__progressBar_text}>
                                <div className={styles.treasuryStats__container__content__box__progressBar_bottomTitle}>
                                    {progressBarBottomTitle}
                                </div>
                                <br />
                                <br />
                                <span className={styles.treasuryStats__container__content__box__progressBar_percentage}>
                                    {diversificationPercentage}
                                    {hasProgressBarPercentage && '%'}
                                </span>
                                <br />
                                <br />
                                {progressBarDescription}
                            </div>
                        </div>
                    </div>
                    <div className={styles.treasuryStats__container__content__box}>
                        <div className={styles.treasuryStats__container__content__box__circular}>
                            <div className={styles.treasuryStats__container__content__box__title}>
                                position in ecosystem
                            </div>
                            <div className={styles.treasuryStats__container__content__box__circular_wrapper}>
                                <Doughnut data={circularData} options={circularOptions} />
                                <div className={styles.treasuryStats__container__content__box__circular_text}>
                                    {avgPercentage}%
                                </div>
                            </div>
                        </div>
                        <div className={styles.treasuryStats__container__content__box__circular_description}>
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationExecutiveStatsItem;

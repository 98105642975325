import React, { FC, ReactElement, memo } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { MAIN_PAGES_BANNER, MAIN_PAGES_BANNER_MOBILE, MAIN_PAGES_BANNER_LINK } from 'constants/banners';

import { FormatUtils } from 'utils';
import { mixpanelService } from 'services';

import { IStatsCard } from 'types/interfaces';

export const StatsCard: FC<IStatsCard> = memo(
    ({
        children,
        className,
        organizationsCount,
        enrichedOrganizationsCount,
        lastUpdateDate,
        title,
        isPeoplePage,
        isDashboardPage,
        isFeedPage,
    }: IStatsCard): ReactElement => {
        const isTablet = useMediaQuery('(max-width:767.98px)');

        const handleClickAd = () => {
            isDashboardPage && mixpanelService.track(mixpanelService.eventsGenerator.dashboardAdEvent());
            isPeoplePage && mixpanelService.track(mixpanelService.eventsGenerator.peopleAdEvent());
            isFeedPage && mixpanelService.track(mixpanelService.eventsGenerator.feedAdEvent());
        };

        const bannerImageUrl = () => {
            return isTablet ? MAIN_PAGES_BANNER_MOBILE : MAIN_PAGES_BANNER;
        };

        const bannerLinkURL = () => {
            return MAIN_PAGES_BANNER_LINK;
        };

        return (
            <div className={classNames(styles.statsCard, className)}>
                <div className={styles.statsWrapper}>
                    <div className={styles.headSection}>
                        <div className={styles.details}>
                            <h2 className={styles.title}>{title}</h2>
                            <h6 className={styles.statsCounters}>
                                {organizationsCount && (
                                    <span>
                                        Aggregated governances:{' '}
                                        <strong>{FormatUtils.formatNumberWithCommas(organizationsCount, 0)}</strong>,
                                    </span>
                                )}
                                {enrichedOrganizationsCount && (
                                    <span>
                                        Enriched by DeepDAO:{' '}
                                        <strong>
                                            {FormatUtils.formatNumberWithCommas(enrichedOrganizationsCount, 0)}
                                        </strong>
                                        ,
                                    </span>
                                )}
                                {lastUpdateDate && (
                                    <span>{FormatUtils.getFormattedLastUpdateDate(lastUpdateDate)}</span>
                                )}
                            </h6>
                        </div>
                        <img src={bannerImageUrl()} className={styles.banner} onClick={handleClickAd} alt="" />
                    </div>

                    {children}
                </div>
            </div>
        );
    },
);

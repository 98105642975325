import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import {
    ConnectWallet,
    // HeaderNotifications,
    // HeaderFollowingButton,
    HeaderRectangularButton,
    MainSocialLinks,
} from 'components';
import { PlansOptions } from './components';

import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';

import { useAppDispatch, useAppSelector } from 'store';
import { AlertActions, ClientsActions, PremiumSubscriptionActions, SelfListingActions } from 'store/actions';
import { useWalletAuth } from 'components/ConnectWallet/useWalletAuth';
import { useHistoryPush, useOutsideClick } from 'hooks';
import { mixpanelService } from 'services';
import {
    getPremiumUserTokenFromStorage,
    removePremiumUserEmailFromStorage,
    removePremiumUserRefreshTokenFromStorage,
    removePremiumUserTokenFromStorage,
    removePremiumSubscriptionIdFromStorage,
} from 'web3/storageHelper';

const FOLLOWING_PAGE_ROUT = '/user/following';
const SOCIALS_COUNT = 2;

export const HeaderButtons: FC = (): ReactElement => {
    const { toggleConnection, token, active } = useWalletAuth();

    const { selfListingModalOpen } = useAppSelector(({ selfListing }) => selfListing);

    const dispatch = useAppDispatch();

    const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

    const { navigateToUserFollowings, navigateToPremiumLogin, navigateToOrganizations } = useHistoryPush();
    const history = useHistory();

    const premiumOptionsRef = useRef<null | HTMLDivElement>(null);

    useOutsideClick(premiumOptionsRef, () => setOptionsOpen(false));

    const isLargeTablet = useMediaQuery('(max-width: 991.98px)');

    const isLogged = useMemo(() => Boolean(token), [token]);

    const isUserWithPremiumIsLogged = Boolean(getPremiumUserTokenFromStorage());

    const onListDaoClick = () => {
        isLogged
            ? dispatch({
                  type: SelfListingActions.TOGGLE_SELF_LISTING_MODAL_OPEN,
                  payload: {
                      type: 'adding',
                  },
              })
            : toggleConnection();

        mixpanelService.track(mixpanelService.eventsGenerator.listDaoMain());
    };

    const navigateToMyFollowings = useCallback(() => {
        isLogged ? navigateToUserFollowings() : toggleConnection();
    }, [toggleConnection, navigateToUserFollowings, isLogged]);

    const premiumLogout = useCallback(() => {
        dispatch({
            type: PremiumSubscriptionActions.CLEAR_PREMIUM_USER_DATA,
        });
        dispatch({
            type: ClientsActions.CLEAR_CREATED_FIELD,
        });
        dispatch({
            type: AlertActions.SHOW_ALERT,
            payload: {
                title: SUCCESS_MESSAGES.PREMIUM_LOGOUT_SUCCESS,
                type: ALERT_TYPES.SUCCESS,
            },
        });
        removePremiumUserTokenFromStorage();
        removePremiumUserRefreshTokenFromStorage();
        removePremiumUserEmailFromStorage();
        removePremiumSubscriptionIdFromStorage();
        setOptionsOpen(false);
        navigateToOrganizations();
    }, [
        removePremiumUserTokenFromStorage,
        removePremiumUserRefreshTokenFromStorage,
        removePremiumUserEmailFromStorage,
        navigateToOrganizations,
        removePremiumSubscriptionIdFromStorage,
        optionsOpen,
    ]);

    return (
        <div className={styles.headerButtons}>
            {!isLargeTablet && <MainSocialLinks maxSocials={SOCIALS_COUNT} className={styles.headerSocialLinks} />}

            {/* <HeaderFollowingButton
                navigateToMyFollowings={navigateToMyFollowings}
                isFollowingPageOpen={history.location.pathname === FOLLOWING_PAGE_ROUT}
            /> */}

            {/* <HeaderNotifications toggleConnection={toggleConnection} isLogged={isLogged} /> */}

            {!isLargeTablet && (
                <>
                    <div className={styles.plansButtonWrapper} ref={premiumOptionsRef}>
                        <HeaderRectangularButton
                            onClick={() => setOptionsOpen(!optionsOpen)}
                            isButtonActive={window.location.pathname === '/products'}
                            title="Data API"
                        />

                        {optionsOpen && (
                            <PlansOptions
                                navigateToPremiumLogin={navigateToPremiumLogin}
                                isUserWithPremiumIsLogged={isUserWithPremiumIsLogged}
                                premiumLogout={premiumLogout}
                            />
                        )}
                    </div>

                    {/*<HeaderRectangularButton*/}
                    {/*    onClick={onListDaoClick}*/}
                    {/*    isButtonActive={selfListingModalOpen}*/}
                    {/*    title="List DAO"*/}
                    {/*/>*/}
                </>
            )}

            <ConnectWallet toggleConnection={toggleConnection} token={token} active={active} />
        </div>
    );
};

import React, { FC, ReactElement, useContext } from 'react';

import {
    OrganizationTreasury,
    OrganizationProposals,
    OrganizationVoters,
    OrganizationProCoalitions,
    OrganizationResearch,
    TabsController,
} from './components';

import {
    TREASURY,
    PROPOSALS,
    MEMBERS,
    COALITIONS,
    RESEARCH,
    DELEGATES,
    VOTER_TRENDS,
    VOTER_INTERESTS,
    TOKEN,
    VOTER_DECENTRALIZATION,
    EXECUTIVE_SUMMARY,
} from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';
import { OrganizationDelegates } from './components/OrganizationDelegates';
import { OrganizationVoterTrends } from './components/OrganizationVoterTrends';
import { OrganizationEcosystem } from './components/OrganizationEcosystem';
import { OrganizationToken } from './components/OrganizationToken';
import { OrganizationCoalitions } from './components/OrganizationCoalitions';
import { OrganizationExecutiveSummary } from './components/OrganizationExecutiveSummary';
import { useAppSelector } from '../../../../store';
import { OrganizationFinance } from './components/OrganizationFinance';
import { OrganizationVoterDecentralization } from './components/OrganizationVoterDecentralization';

export const OrganizationData: FC = (): ReactElement => {
    const { organizationDataTabSelected } = useContext(OrganizationContext);

    const { organizationTabs } = useAppSelector(({ organizationTabs }) => organizationTabs);

    const isTreasuryProTab = organizationTabs.data.find((t) => t.tab.name === TREASURY);
    const isCoalitionsProTab = organizationTabs.data.find((t) => t.tab.name === COALITIONS);

    const treasuryTab = isTreasuryProTab ? <OrganizationTreasury /> : <OrganizationFinance />;
    const coalitionsTab = isCoalitionsProTab ? <OrganizationProCoalitions /> : <OrganizationCoalitions />;

    const renderOrganizationDataComponent = () => {
        switch (organizationDataTabSelected) {
            case EXECUTIVE_SUMMARY:
                return <OrganizationExecutiveSummary />;

            case TREASURY:
                return treasuryTab;

            case TOKEN:
                return <OrganizationToken />;

            case PROPOSALS:
                return <OrganizationProposals />;

            case MEMBERS:
                return <OrganizationVoters />;

            case COALITIONS:
                return coalitionsTab;

            case RESEARCH:
                return <OrganizationResearch />;

            case DELEGATES:
                return <OrganizationDelegates />;

            case VOTER_DECENTRALIZATION:
                return <OrganizationVoterDecentralization />;

            case VOTER_TRENDS:
                return <OrganizationVoterTrends />;

            case VOTER_INTERESTS:
                return <OrganizationEcosystem />;

            default:
                return treasuryTab;
        }
    };

    return (
        <>
            <TabsController />
            {renderOrganizationDataComponent()}
        </>
    );
};

export const formatNumber = (input: number): string => {
    if (input === 0) return input.toString();

    const numberString = input.toString();

    if (!numberString.includes('e') && !numberString.startsWith('0')) {
        return Number.isInteger(input)
            ? numberString
            : parseFloat(input.toFixed(1)) % 1 === 0
            ? Math.round(input).toString()
            : input.toFixed(1);
    }

    const expanded = input.toFixed(20).replace(/0+$/, '');
    const match = expanded.match(/^(.*?0)([1-9])([0-9]?)/);

    if (match) {
        return match[1] + match[2] + (match[3] || '0');
    }

    return parseFloat(expanded).toExponential();
};

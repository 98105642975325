import { EXECUTIVE_SUMMARY_URL } from 'constants/organizationTabs';
import { useHistory } from 'react-router-dom';

import { IUseHistoryPushHook } from 'types/interfaces';

export const useHistoryPush = (id?: string): IUseHistoryPushHook => {
    const history = useHistory();

    const navigateToOrganization = () => {
        history.push(`/organization/${id}/organization_data/${EXECUTIVE_SUMMARY_URL}`);
    };

    const navigateToUserInfo = () => {
        history.push(`/user/${id}/verified_dao_experience`);
    };

    const navigateToUserFollowings = () => {
        history.push(`/user/following`);
    };

    const navigateToProducts = () => {
        history.push(`/products`);
    };

    const navigateToPlans = () => {
        history.push('/plans');
    };

    const navigateToAccountInvoices = () => {
        history.push('/premium_account_invoices');
    };

    const navigateToPremiumLogin = () => {
        history.push('/premium_email_login');
    };

    const navigateToPremiumOTPLogin = () => {
        history.push('/premium_otp_login');
    };

    const navigateToOrganizations = () => {
        history.push('/organizations');
    };

    const navigateToPremiumUpdate = () => {
        history.push('/premium_plan_upgrade');
    };

    const navigateToAccountSettings = () => {
        history.push('/premium_account_settings');
    };

    return {
        navigateToOrganization,
        navigateToUserFollowings,
        navigateToUserInfo,
        navigateToPlans,
        navigateToProducts,
        navigateToAccountInvoices,
        navigateToPremiumLogin,
        navigateToOrganizations,
        navigateToPremiumUpdate,
        navigateToAccountSettings,
        navigateToPremiumOTPLogin,
    };
};
